import React, {useState, useEffect} from 'react';
import { Tooltip, IconButton, Button} from '@mui/material';
const DEFAULT_TEXTAREA_FIELDS=['textBody']


const styles = {
    root:{
        margin:'auto',
        overflow:'auto',
        paddingBottom:20, 
        paddingLeft:20,
    },
    table:{
        fontSize:22,
        padding:20
    },
    tr: active=>({
        // textDecoration:active?'none':'line-through',
        fontStyle:active?'normal':'italic',
        opacity:active?1.0:0.6,
        backgroundColor:active?'lightGrey':'whitesmoke',
        wordWrap:'break-word',
        width:20,
    }),
    th:{
        color:'white'
    },
    td: {
        wordWrap:'break-word',
        maxLength:20
    },
    add: {
        wordWrap:'break-word',
    },
    search: {
        wordWrap:'break-word',
    }
}

// EditRecord
export default ({cols, record, setRecord, buttons}) => {
    const colObjList = cols.map(it => ({
        Field:it,
        type:'text'
    })
    )

    const handleChange = e => setRecord({...record, [e.target.name]:e.target.value})
    return (
        <>
        {record?
            <table>
                <thead>
                    <tr>
                        <th style={styles.th}>Name</th>
                        <th style={styles.th}>Value</th>
                    </tr>
                </thead>

                <tbody>
                    {colObjList.map((col, index)=>
                        <tr>
                            <th style={styles.th}>
                                {col.Field}
                            </th>
                            <td>
                                {col.type==='textarea'?
                                    <textarea 
                                        autoFocus={index==0?true:undefined} 
                                        style={styles.add} 
                                        rows={3} 
                                        cols={50} 
                                        placeholder={col.Field} 
                                        name={col.Field} 
                                        value = {record[col.Field]} 
                                        onChange={handleChange}
                                    />
                                :
                                    <input 
                                        autoFocus={index==0?true:undefined} 
                                        style={styles.add} 
                                        type={col.type} 
                                        placeholder={col.Field} 
                                        name={col.Field} 
                                        value = {record[col.Field]} 
                                        onChange={handleChange}
                                    />
                                }    
                            </td>
                        </tr>
                    )}  

                    {buttons?     
                        <tr>
                            <td colSpan={2}>
                                {buttons.map(button => 
                                    button.icon?
                                            <Tooltip title={<h2>{button.tooltip}</h2>}>
                                                <IconButton onClick={()=>button.onClick()}>
                                                    {button.icon}                            
                                                </IconButton>
                                            </Tooltip>
                                    :    
                                            <Button variant={button.variant?button.variant:'outlined'} style={{color:'white'}} onClick={()=>button.onClick()}>{button.label?button.label:'No label'}</Button>
                                )}    
                            </td>
                        </tr>
                    :
                        <tr>
                            <td colSpan={2}>No buttons passed to _RenderEdit</td>
                        </tr>
                    }
                </tbody>
            </table>
        
        :
            <h3>No record</h3>
        }
        </>

    )

}
