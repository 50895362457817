import React, {useState, useEffect} from 'react';
import {isNormalVariable} from '../services/functions'
import {IconButton, Button, Tooltip} from '@mui/material';
import EditRecord from './EditRecord'
import {addRow, replaceRow, deleteRow} from '../services/serverPost'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import AddIcon from '@mui/icons-material/Add';
import EmailIcon from '@mui/icons-material/Email'
import SearchIcon from '@mui/icons-material/Search'
import SaveIcon from '@mui/icons-material/Save'
import CancelIcon from '@mui/icons-material/Cancel'
const DEFAULT_TEXTAREA_FIELDS=['textBody']

const styles = {
    container:{
        overflow:'auto',
        width:'100vw'
    },
    th:{
        padding:4,
    },
    td:{
        fontSize:'normal',
        padding:20
    }
}


// _View uses id and not index to identify a row
const _View = ({list, cols, buttons})=> {
    return(
        cols?
            <div style={styles.container}>
                <table>
                    <thead>
                        <tr>
                            {cols.map(col=>
                                <th style={styles.th}>{col}</th>
                            )}
                            {buttons?buttons.map(button =>
                                <th style={styles.th}>
                                    {button.label?button.label:'Button'}
                                </th>
                            ):null}
                        </tr>
                    </thead>
                    <tbody>
                        {list.map((li, idx)=>
                            <tr>
                                {cols.map(col=>
                                    <td style={styles.td}>{(typeof li[col] == "boolean")?(li[col]?'true':'false'):li[col]}</td>
                                )}    
                                {buttons?buttons.map(button =>
                                    <>
                                        {button.icon?
                                            <td style={styles.td}>
                                                <Tooltip title={button.tooltip}>
                                                    <IconButton onClick={()=>button.onClick?button.onClick(li):null} >
                                                        {(typeof button.icon === 'function')?button.icon(li.id):button.icon}
                                                    </IconButton>    
                                                </Tooltip>    
                                            </td>
                                        :   
                                            <td style={styles.td}>
                                                <Tooltip title={button.tooltip}>
                                                    <Button onClick={()=>button.onClick?button.onClick(li):null}>
                                                        {button.label?button.label:'Button'}
                                                    </Button>
                                                </Tooltip>   
                                            </td>
                                        }    
                                    </>
                            ):null}
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
    :
        <h1>No colsView</h1>  
    )   
}

// ViewTable
export default ({tableName, colsView, colsEdit, list, handleReplaceRecord}) => {
    const [edit, setEdit] = useState()
    const [record, setRecord] = useState()

    const buttonsView = [
        {
            icon: id => id===edit?<SaveIcon/>:<EditIcon/>,
            onClick:rec=>{
                setEdit(edit===rec.id?undefined:rec.id)        
                setRecord(rec)
            }    

        },    
        {
            icon:<CancelIcon />,
            onClick:id=>setEdit(undefined)        
        }    
    ]

    const handleReply = reply => {
        const data = reply.data?reply.data:reply
        if (data.status === 'OK') {
            handleReplaceRecord(record)
            setEdit()
            setRecord()
        } else {
            alert('[ViewTable] Failed to save record ' + JSON.stringify(record))
        }
    }

    const buttonsEdit = [
        {
            icon:<SaveIcon />,
            onClick:()=>{
                replaceRow(tableName, record, handleReply) 
            }
        },
        {
            icon:<CancelIcon />,
            onClick:()=>{
                setEdit()
                setRecord()
            }    
        },
    ]


    return(
    list?record?
        <EditRecord 
            cols={colsEdit} 
            record = {record} 
            setRecord={setRecord}
            buttons={buttonsEdit} 
        />
    :
        <_View 
            list={list} 
            cols={colsView} 
            buttons={buttonsView} 
        />
    :
        <h1>No record in list</h1>    
    )
}
