import {useState, useEffect} from 'react'
import {useSharedState} from '../store'
import { useNavigate }  from 'react-router-dom';
import {isNormalVariable} from '../services/functions'
import {serverFetchData} from "../services/serverFetch"
import {GroupByRecursive} from "../components/GroupByRecursive"
import CirkularProgress from '../components/CirkularProgress'
import ViewTable from '../components/ViewTable'

const defaultStyle = {
    height:30, 
    marginTop:5, 
    textAlign:'center', 
    margin:'auto', 
    color:'yellow', 
    backgroundColor:'black' 
}

const styles = {
    button:{color:'blue', 
        borderColor:'blue', 
        padding:2,
        fontSize:12
    },
    icon:{
        padding:0, 
        background:'transparent', 
        color:'blue', 
        borderColor:'blue'
    },
    table:{
        borderCollapse:'collapse',
        //border:'1px solid red',
    },
    tr:{
        background:'whiteSmoke'
    },
    course:{
        padding:10, 
        fontSize:14, 
        margin:'auto', 
        backgroundColor:'transparent',
        borderRadious:8,
    }
}

const _ViewBar = props => {
    const {depth, groupByArr, list} = props
    const groupByItem = groupByArr[depth]
    const style = groupByItem.style?groupByItem.style:defaultStyle
    const columns = groupByItem.columns
    return(
        <div style={style}>
            {columns.map(col=>
                    <span col>
                        {list[0][col]}&nbsp;
                    </span>
            )}
        </div>
    )
}
const apiBaseUrl=process.env.REACT_APP_API_BASE_URL;

const _ViewTable = props => {
    const {depth, groupByArr, list, handleReplaceRecord} = props
    const groupByItem = groupByArr[depth]
    const tableName = groupByItem.tableName
    const allCols = Object.keys(list[0])
    const colsView = groupByItem.colsView?groupByItem.colsView:allCols
    const colsEdit = groupByItem.colsEdit?groupByItem.colsEdit:allCols

    return(
        list?
            <ViewTable tableName={tableName} colsView={colsView} colsEdit={colsEdit} list={list} handleReplaceRecord={handleReplaceRecord} />
        :
            <h1>List empty</h1>    
    )
}


// Each row is one level of the recursive groupBy process, first row is group by city, next row is group by courseType, and finally group by courseId
const groupByArr = [
    {
        groupByFunc:it=>it.eventType, 
        columns:['eventType'],
        style:{margin:'auto', width:'80vw', backgroundColor:'darkBlue', color:'silver', margin:'auto', marginTop:2},
        RenderView:_ViewBar
    }, 
    {
        groupByFunc:it=>it.eventType, 
        columns:['eventType'],
        style:{margin:'auto', width:'80vw', backgroundColor:'blue', color:'white', margin:'auto', marginTop:2},
        RenderView:_ViewBar
    }, 
    {
        groupByFunc:it=>it.startDate,
        columns:['startDate'],
        style:{margin:'auto', width:'70vw', backgroundColor:'lightBlue', color:'black', margin:'auto', marginTop:2},
        RenderView:_ViewBar
    }, 
    {
        //groupByFunc:it=>it.firstName,
        colsEdit:['firstName', 'lastName', 'role', 'email', 'phone'],
        colsView:['firstName', 'lastName', 'role', 'email', 'phone', 'firstNamePartner', 'lastNamePartner', 'emailPartner', 'message'],
        tableName:'tbl_registration',
        RenderView:_ViewTable, 
    }
]

export default  () => {
    const [list, setList] = useState()
    const [columns, setColumns] = useState()
    const [sharedState, ] = useSharedState()
    const language = sharedState.language?sharedState.language:'EN'

    const handleReplaceRecord = rec => {
        if (rec.id) {
            const newList = list.map(it=>{
                if (it.id === rec.id) {
                    return rec 
                } else {
                    return it
                }    
            })    
            setList(newList)
        } else {
            alert('[ListCourseRegistrations]: object is missing key "id" and can therefore not be updated')
        }    
    }

    const handleReply1 = data => {
        if (data.status === 'OK' || data.status === 'true') {
            setList(data.result.filter(it=>isNormalVariable(it)))
        } else {
            alert(data.message)
        }
    }

    const handleReply2 = data => {
        if (data.status === 'OK' || data.status === 'true') {
            setColumns(data.result.filter(it=>isNormalVariable(it)))
        } else {
            alert(data.message)
        }
    }

    useEffect(()=>{
            const url1 = apiBaseUrl + '/listFestivalRegistrations?productType=course&language=EN'
            serverFetchData(url1, handleReply1)
            const url2='/getColumns?tableName=tbl_registration_festival'
            serverFetchData(url2, handleReply2) 
    },[language])

    return(
        <>
            {list?
                <GroupByRecursive depth={0} groupByArr={groupByArr} list={list} handleReplaceRecord={handleReplaceRecord} columns={columns} language={language} />
            :
                <CirkularProgress color={'whiteSmoke'} style={{margin:'auto', width:'100vw'}} />
            }
        </>
    )
}
