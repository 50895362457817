import {useState, useEffect} from 'react'
const styleDefault = {textAlign:'center', margin:2, color:'yellow', margin:'auto', width:'100vw'}

export const GroupByFlat = props => {
    const {depth, groupByArr, list, language} = props
    const recursionReady = (depth === groupByArr.length) 

    if (recursionReady) {
        // Just return the view of the table with the remaining list
        return;
    } else {
        // Return recursive view and call make recursive call again until gropuByArr reached depth
        const groupByItem = groupByArr[depth]
        const RenderView = groupByItem.RenderView?groupByItem.RenderView:undefined
        const groupByFunc = groupByItem.groupByFunc?groupByItem.groupByFunc:undefined
        const groups = groupByFunc?Object.groupBy(list, groupByFunc):undefined
        const className = groupByItem.className
        const classNameItem = groupByItem.classNameItem
        return(
            <div className={className}>
            {groups?Object.keys(groups).map(key=>
                <div className={classNameItem}>
                    {RenderView?<RenderView key={key} depth={depth} groupByArr={groupByArr} list={groups[key]} language={language} />:null}    
                    <GroupByFlat depth={depth+1} groupByArr={groupByArr} list={groups[key]} language={language} />
                </div>
            ):<h5 className='title-is-5'>No groups</h5>}
            </div>
        )
    }
}    

// GroupByRecursive
export const GroupByRecursive = props => {
    // Note that handleChange must be defined in top level since it has to replace the full list
    const {depth, groupByArr, list, handleReplaceRecord, columns, language} = props
    const [open, setOpen] = useState()
    const recursionReady = (depth === groupByArr.length) 
    const lastIteration = (depth === groupByArr.length-1) 

        // Return recursive view and call make recursive call again until gropuByArr reached depth
        const groupByItem = groupByArr[depth]
        const RenderView = groupByItem.RenderView
        const groupByFunc = groupByItem.groupByFunc
        const groups = groupByFunc?Object.groupBy(list, groupByFunc):undefined
        const style = groupByItem.style?groupByItem.style:styleDefault
        const className = groupByItem.className
        const handleClick = key => {setOpen(open?undefined:key)}
        const keys = groups?Object.keys(groups):[]
        
        return(
            lastIteration?
                <RenderView 
                    style={style} 
                    depth={depth} 
                    groupByArr={groupByArr} 
                    list={list} 
                    handleReplaceRecord={handleReplaceRecord} 
                    columns={columns} 
                    language={language} 
                />
            :
                keys.map(key=>
                    <div className={className}>
                        {(!open||open===key)?
                        <div onClick={()=>handleClick(key)}>
                            {RenderView?<RenderView 
                                style={style} 
                                key={key} 
                                depth={depth} 
                                groupByArr={groupByArr} 
                                list={groups[key]} 
                                handleReplaceRecord={handleReplaceRecord} 
                                columns={columns} 
                                language={language} />:null}    
                        </div>
                    :null}
                    {open && !lastIteration && (open===key)?
                        <GroupByRecursive 
                            depth={depth+1} 
                            groupByArr={groupByArr} 
                            list={groups[key]} 
                            handleReplaceRecord={handleReplaceRecord} 
                            columns={columns} 
                            language={language}
                        />
                    :null}
                    </div>
                )
            )
}    

export default GroupByRecursive


